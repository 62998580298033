<script>
import GlobalVue from '@root/helper/Global.vue';
export default {
  name: "NavBar",
  extends: GlobalVue,
  data(){
    return{
      notif:{}
    }    
  },
  computed:{
    ufullname() {
      return this.user ? this.user.bu_full_name : "-"
    }
  },
  mounted(){
    window.nav = this
  },
  methods:{
    hideLogo(){
      if($('#lerge-logo').hasClass( "hide-menu" )){
        $('#lerge-logo').removeClass( "hide-menu" )
        $('#small-logo').addClass( "hide-menu" )
      }
      else{
        $('#lerge-logo').addClass( "hide-menu" )
        $('#small-logo').removeClass( "hide-menu" )
      }
    },
    logout() {
      this.page.logout()
    },
  }
};
</script>

<template>
    <header class="topbar">
      <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          <!-- ============================================================== -->
          <!-- Logo -->
          <!-- ============================================================== -->
          <div class="navbar-header text-center">
              <a class="navbar-brand px-2">
                  <img :src="assets('bo_images','logo-text.png')" alt="homepage" class="dark-logo" />
              </a>
          </div> 
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <div class="navbar-collapse">
              <!-- ============================================================== -->
              <!-- toggle and nav items -->
              <!-- ============================================================== -->
              <ul class="navbar-nav mr-auto">
                  <!-- This is  -->
                  <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                  <li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
                  <!-- ============================================================== -->
                  <!-- Search -->
                  <!-- ============================================================== -->
                  <!-- <li class="nav-item">
                      <form class="app-search d-none d-md-block d-lg-block">
                          <input type="text" class="form-control" placeholder="Search & enter">
                      </form>
                  </li> -->
              </ul>
              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
              <ul class="navbar-nav my-lg-0">
                  <!-- ============================================================== -->
                  <!-- Comment -->
                  <!-- ============================================================== -->
                
                 
                  <li class="nav-item dropdown u-dropdown">
                    <a class="nav-link align-items-center dropdown-toggle u-dropdown link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="javascript:void(0)">
                      <VImg :src="assets('bo_images','user.png')" :lazy-src="uploader(user.bu_pic,'50x50c')" alt="user-img" class="img-circle user-img"></VImg>
                      <div class="mx-2">{{(ufullname||'').limitChar(20)}}</div>
                      <i class="icon-arrow-down"></i>
                    </a>
                    <div class="dropdown-menu animated fadeIn faster">
                      <div class="dropdown-divider"></div>
                      <router-link :to="{name:'BoProfile'}" class="dropdown-item"><i class="ti-settings"></i> Account Setting
                      </router-link>
                      <div class="dropdown-divider"></div>
                      <a href="javascript:;" @click="logout()" class="dropdown-item"><i class="fa fa-power-off"></i> Logout</a>
                    </div>
                  </li>
              </ul>
          </div>
      </nav>
  </header>
</template>
